<template>
    <div style="height: 100vh">
      <router-view />


      
    </div>
  </template>
  
  <script>
    export default {
      name: "three",
      data() {
        return {
        }
      },
      methods: {
  
      }
    }
  </script>
  
  <style scoped>
  
  </style>
  